import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import authFetch from "./Interceptor";
import "../assets/css/gamelog.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";

const GameLogPic = () => {
  const videoRef = useRef();

  const [gameLogs, setGameLogs] = useState([]);

  useEffect(() => {
    try {
      authFetch
        .get("/api/game/log/list/")
        .then((response) => {
          setGameLogs(response?.data);
          console(response);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const video = document.querySelector(".tiktok-video");
  const pauseVideo = () => {
    video.pause();
  };

  return (
    <React.Fragment>
      {/* icons */}
      <section className="game-icons-section">
        <Container>
          <Row>
            <Col md={1} lg={2}></Col>
            <Col md={10} lg={8}>
              <Row className="gy-3">
                {gameLogs &&
                  gameLogs.map((item, index) => {
                    return (
                      <>
                        <div
                          class="modal fade"
                          id={`exampleModal${item?.id}`}
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog modal-dialog-centered modal_gamelog_img">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={pauseVideo}
                                ></button>
                              </div>
                              <div class="modal-body">
                                {item?.img ? (
                                  <img
                                    src={item?.img}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                ) : (
                                  <video
                                    controls
                                    className="tiktok-video"
                                    style={{
                                      width: "100%",
                                      maxHeight: "350px",
                                    }}
                                  >
                                    <source
                                      src={item?.video}
                                      type="video/mp4"
                                    />
                                  </video>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </Row>
            </Col>
            <Col md={1} lg={2}></Col>
          </Row>

          <Row className="gy-3">
            <Col md={1} lg={2}></Col>
            <Col md={10} lg={8}>
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                slidesPerGroup={3}
                loop={true}
                loopFillGroupWithBlank={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                breakpoints={{
                  768: { slidesPerView: 3 },
                  640: { slidesPerView: 3 },
                  576: { slidesPerView: 3 },
                  250: { slidesPerView: 2 },
                }}
                className="mySwiper"
              >
                {gameLogs &&
                  gameLogs.map((item, index) => {
                    return (
                      <>
                        <SwiperSlide>
                          <Col
                            data-bs-toggle="modal"
                            data-bs-target={`#exampleModal${item?.id}`}
                          >
                            <div class=" gamelog-icons-section-card">
                              <div class="card-body">
                                <div className="main-gamelog-icon gamelog text-center">
                                  {item?.img ? (
                                    <img src={item?.img} />
                                  ) : (
                                    <video
                                      controls={false}
                                      className="tiktok-video"
                                      muted
                                    >
                                      <source
                                        src={item?.video}
                                        type="video/mp4"
                                      />
                                    </video>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </SwiperSlide>
                      </>
                    );
                  })}
              </Swiper>
            </Col>
            <Col md={1} lg={2}></Col>
          </Row>
        </Container>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </section>
      {/* icons ends */}
    </React.Fragment>
  );
};

export default GameLogPic;
