import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/icons.css";
import authFetch from "./Interceptor";

const GameIcons = () => {
  const [games, setGames] = useState([]);

  const navigate = useNavigate();

  const handleRedirection = (url) => {
    console.log(url);
    navigate(url);
  };

  useEffect(() => {
    try {
      authFetch
        .get("/api/game/list/")
        .then((response) => {
          setGames(response?.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <React.Fragment>
      {/* icons */}
      <section className="game-icons-section">
        <Container>
          <Row>
            <Col md={1} lg={2}></Col>
            <Col md={10} lg={8}>
              <Row className="gy-3">
                {games &&
                  games.map((item) => {
                    return (
                      <Col xs={6} sm={4} md={4} lg={4}>
                        <div class=" game-icons-section-card">
                          <div class="card-body">
                            <div className="main-game-icon text-center">
                              <img src={item?.img} />
                            </div>
                            <div className="apple-android-icon text-center">
                              <div className="apple-android-button-with-icons">
                                <a href={item?.apple_link}>
                                  <img
                                    src={require("../assets/images/icons/apple.png")}
                                  />
                                </a>

                                <a href={item?.android_file}>
                                  <img
                                    src={require("../assets/images/icons/android.png")}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
            <Col md={1} lg={2}></Col>
          </Row>
        </Container>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </section>
      {/* icons ends */}
    </React.Fragment>
  );
};

export default GameIcons;
