import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import BodyBackgroundImage from "../assets/images/bg.png";
import Profile from "./Profile";
import Feedback from "./Feedback";
import authFetch from "./Interceptor";

// css files
import "../assets/css/index.css";
import "../assets/css/media-queries.css";

// custom components
import GameIcons from "./GameIcon";

const Index = () => {
  const [siteLogo, setSiteLogo] = useState("");
  const [videoLink, setVideoLink] = useState("");

  useEffect(() => {
    try {
      authFetch
        .get("/api/video/link/")
        .then((response) => {
          console.log(response?.data);
          setVideoLink(response?.data?.video_link);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }

    try {
      authFetch
        .get("/api/site/logo/")
        .then((response) => {
          setSiteLogo(response?.data?.logo);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }

    // backgroun image
    try {
      authFetch
        .get("/api/background/image/list/")
        .then((response) => {
          if (response?.data) {
            document.body.style.backgroundImage =
              "url(" + response?.data?.img || BodyBackgroundImage + ")";
            document.body.style.backgroundRepeat = "no-repeat";
            document.body.style.backgroundAttachment = "scroll";
            document.body.style.backgroundSize = "100% 100%";
          }
          console.log(response?.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="root">
        <Row>
          <Col md={1} lg={2}></Col>
          <Col md={10} lg={8}>
            <Container>
              <Row>
                <Col>
                  <div className="logo">
                    {siteLogo ? (
                      <img src={siteLogo} />
                    ) : (
                      <img src={require("../assets/images/logo.png")} />
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="video-category-section">
                  <div style={{ width: "100%" }}>
                    <iframe
                      style={{ width: "100%", height: "415px" }}
                      src={videoLink ? videoLink : ""}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </Col>

                <Col md={12}>
                  <Row>
                    <Col md={12} className="gy-5 category-buttons">
                      <Row>
                        <Col xs={5} sm={5} md={5}>
                          <Link to="/">
                            <img
                              src={require("../assets/images/button-1.png")}
                            />
                          </Link>
                        </Col>
                        <Col xs={2} sm={2} md={2}></Col>
                        <Col xs={5} sm={5} md={5}>
                          <Link to="/profile">
                            <img
                              src={require("../assets/images/button-2.png")}
                            />
                          </Link>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={5} sm={5} md={5}>
                          <Link to="/gamelog">
                            <img
                              src={require("../assets/images/button-3.png")}
                            />
                          </Link>
                        </Col>
                        <Col xs={2} sm={2} md={2}></Col>
                        <Col xs={5} sm={5} md={5}>
                          <Link to="/feedback">
                            <img
                              src={require("../assets/images/button-4.png")}
                            />
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={1} lg={2}></Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Index;
