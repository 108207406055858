import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BsInstagram } from "react-icons/bs";
import { FaTelegramPlane, FaWhatsapp, FaFacebookF } from "react-icons/fa";
import { useSnackbar } from "notistack";
import "../assets/css/profile.css";
import { Link } from "react-router-dom";
import BackGroundImg from "../assets/images/profile-bg.png";
import authFetch from "./Interceptor";

const Profile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [profiles, setProfiles] = useState([]);

  const copyWhatsappNumber = (e) => {
    console.log(e.target.getAttribute("id"));
    navigator.clipboard.writeText(e.target.getAttribute("id"));
    enqueueSnackbar("Copied!", { variant: "success", autoHideDuration: 2000 });
  };

  useEffect(() => {
    try {
      authFetch
        .get("/api/profile/list/")
        .then((response) => {
          setProfiles(response?.data);
          console(response);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Row className="mt-3">
          <Col md={1} lg={2}></Col>
          <Col md={10} lg={8}>
            <Row className="profile-cards gy-5">
              {profiles &&
                profiles.map((item) => {
                  return (
                    <Col md={12}>
                      <div
                        class="card"
                        style={{
                          backgroundImage: `url(${
                            item?.background_img
                              ? item?.background_img
                              : BackGroundImg
                          })`,
                        }}
                      >
                        <div class="card-body">
                          <div className="profile-avatar">
                            <img src={item?.img} />
                          </div>
                          <div className="social-icons">
                            <div
                              className="whatsapp-icon"
                              onClick={copyWhatsappNumber}
                              id={item?.whatsapp}
                            >
                              <FaWhatsapp
                                className="icon whatsapp-main-icon"
                                id={item?.whatsapp}
                              />
                            </div>

                            <a href={item?.tg} target="_blank">
                              <div className="telegram-icon">
                                <FaTelegramPlane className="icon" />
                              </div>
                            </a>

                            <a href={item?.fb} target="_blank">
                              <div className="facebook-icon">
                                <FaFacebookF className="icon" />
                              </div>
                            </a>

                            <a href={item?.insg} target="_blank">
                              <div className="instagram-icon">
                                <BsInstagram className="icon" />
                              </div>
                            </a>
                          </div>
                        </div>

                        <img
                          src={require("../assets/images/icons/agent-2.png")}
                          className="profile-start-rating"
                        />
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </Col>
          <Col md={1} lg={2}></Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Profile;
