import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import authFetch from "./Interceptor";
import { useSnackbar } from "notistack";

import "../assets/css/feedback.css";

const Feedback = () => {
  const { enqueueSnackbar } = useSnackbar();

  const initialFeedbackObj = Object.freeze({
    gameProblem: false,
    dealerComplain: false,
    delaerCheat: false,
    other: false,
    phone: "",
    contact: "",
    complain: "",
  });

  const [feedback, setFeedback] = useState(initialFeedbackObj);

  const onChangeHandler = (e) => {
    if (e.target.type === "checkbox") {
      setFeedback({ ...feedback, [e.target.name]: e.target.checked });
    } else {
      setFeedback({ ...feedback, [e.target.name]: e.target.value });
    }
  };

  const sendFeedbackHandler = () => {
    try {
      authFetch
        .post("/send/feedback/", feedback)
        .then((response) => {
          const msg = response?.data?.success
            ? response.data.success
            : response.data.error;
          enqueueSnackbar(msg, {
            variant: `${response?.data?.success ? "success" : "warning"}`,
            autoHideDuration: 5000,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col md={1} lg={2}></Col>
          <Col md={10} lg={8}>
            <Row className="feedback-title mt-3 gy-4">
              <Col className="text-center">
                <img src={require("../assets/images/icons/sss_roy.png")} />
              </Col>
            </Row>

            <Row className="gy-5">
              <Col>
                <div className="choose-option  mt-5">
                  <div className="title text-center">
                    <img
                      className="mt-4"
                      src={require("../assets/images/checkbox-text/choose-type.png")}
                    />
                  </div>
                  <div className="checkbox-list">
                    <Row>
                      <Col xs={8} sm={7} md={6}>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            name="gameProblem"
                            onChange={onChangeHandler}
                          />
                          <label
                            class="form-check-label labelTextColor"
                            for="flexCheckDefault"
                          >
                            Game Problem
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault2"
                            name="dealerComplain"
                            onChange={onChangeHandler}
                          />
                          <label
                            class="form-check-label labelTextColor"
                            for="flexCheckDefault2"
                          >
                            Dealer Complaints
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault3"
                            name="delaerCheat"
                            onChange={onChangeHandler}
                          />
                          <label
                            class="form-check-label labelTextColor"
                            for="flexCheckDefault3"
                          >
                            Dealer Cheat
                          </label>
                        </div>
                      </Col>

                      <Col xs={4} sm={5} md={6}>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault1"
                            name="other"
                            onChange={onChangeHandler}
                          />
                          <label
                            class="form-check-label labelTextColor"
                            for="flexCheckDefault1"
                          >
                            Others
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="input-fields  mt-3">
                  <input
                    // class="form-control form-control-lg"
                    type="text"
                    placeholder="Your phone number"
                    aria-label="form-control-lg example"
                    name="phone"
                    onChange={onChangeHandler}
                  />
                </div>

                <div className="input-fields  mt-3">
                  <input
                    // class="form-control form-control-lg"
                    type="text"
                    placeholder="Your contact details"
                    aria-label="form-control-lg example"
                    name="contact"
                    onChange={onChangeHandler}
                  />
                </div>

                <div className="textarea-fields mt-3">
                  <textarea
                    placeholder="Your complaints info"
                    onChange={onChangeHandler}
                    name="complain"
                  ></textarea>
                </div>

                <input
                  type="button"
                  placeholder="Your contact details"
                  aria-label="form-control-lg example"
                  value="Send to us"
                  className="submit-feedback-btn mt-3"
                  onClick={sendFeedbackHandler}
                />
              </Col>
            </Row>
          </Col>
          <Col md={1} lg={2}></Col>
        </Row>
      </Container>

      <Row>
        <br />
        <br />
      </Row>
    </React.Fragment>
  );
};

export default Feedback;
