import React from "react";
import App from "./App";
import { SnackbarProvider } from "notistack";

const RootApp = () => {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <App />
    </SnackbarProvider>
  );
};

export default RootApp;
