import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GameIcons from "./components/GameIcon";
import Index from "./components/Index";
import Profile from "./components/Profile";
import Feedback from "./components/Feedback";
import GameLogPic from "./components/GameLogPic";

function App() {
  return (
    <div style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
      <BrowserRouter>
        <Index />
        <Routes>
          <Route path="/" element={<GameIcons />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/feedback" element={<Feedback />}></Route>
          <Route path="/gamelog" element={<GameLogPic />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
